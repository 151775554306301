<template>
  <div class="order-info">
    <h3 style="margin-bottom: 20px">求购单详情</h3>

    <!-- 报价商品 -->
    <div class="title">
      <span class="icon"></span>
      <span class="text">求购商品&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span class="">求购时间：{{ orderInfo.createDate }}</span>
    </div>
    <!-- 商品信息 -->

    <div class="info-box-noborder">
      <Table
        v-if="isEdit"
        border
        :columns="columns"
        :data="productList"
        :fixed-shadow="'auto'"
      >
        <template #productName="{}">
          <Input
            v-model="productList.productName"
            disabled
            :placeholder="orderInfo.productName"
          />
        </template>
        <template #formate="{}">
          <Input
            v-model="productList.formate"
            disabled
            :placeholder="orderInfo.formate"
          />
        </template>
        <template #material="{}">
          <Input
            v-model="productList.material"
            disabled
            :placeholder="orderInfo.material"
          />
        </template>

        <template #count="{}">
          <Input v-model="productList[0].count" type="number" />
        </template>
        <template #price="{}">
          <Input v-model="productList[0].price" type="number" />
        </template>
        <template #unitName="{}">
          <Select
            v-model="productList[0].unitName"
            :transfer="true"
            :placeholder="orderInfo.unitName"
          >
            <Option v-for="item in unitsList" :key="item" :value="item">
              {{ item }}
            </Option>
          </Select>
        </template>
        <template #tradeType="{}">
          <Select
            v-model="orderInfo.tradeType"
            style="width: 120px"
            :transfer="true"
          >
            <Option
              v-for="item in tradeTypeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </Option>
          </Select>
          <!-- <Input v-model="productList.tradeType" disabled :placeholder="orderInfo.tradeType" /> -->
        </template>
        <template #action="{}">
          <Button
            type="text"
            size="small"
            style="color: #80beff; background: none; box-shadow: none"
            @click="saveEdit()"
          >
            <!--  @click="saveEdit()" -->
            保存
          </Button>
        </template>
      </Table>
      <Table v-else border :columns="columns" :data="goodsData">
        <template #action="{}">
          <label style="color: #409eff" @click="edit()">修改</label>
        </template>
      </Table>
    </div>

    <!-- 求购单信息 -->
    <div class="title">
      <span class="icon"></span>
      <span class="text">求购单信息</span>
    </div>
    <div class="info-box-noborder">
      <dl>
        <dt>求购信息</dt>
        <dd>{{ orderInfo.title }}</dd>
      </dl>
      <dl>
        <dt>电话</dt>
        <dd>{{ orderInfo.tel }}</dd>
      </dl>
      <dl>
        <dt>邮箱地址</dt>
        <dd>{{ orderInfo.email }}</dd>
      </dl>
      <dl>
        <dt>配送方式</dt>
        <dd>自提</dd>
      </dl>
      <dl>
        <dt>备注说明</dt>
        <dd>
          {{ orderInfo.remark }}
        </dd>
      </dl>
    </div>
    <!-- 报价人信息 -->
    <div class="title">
      <span class="icon"></span>
      <span class="text">报价人信息</span>
    </div>
    <div class="info-box-noborder">
      <Table :columns="columnsInfo" :data="infoData">
        <template #action="{ row }">
          <label
            style="color: #409eff"
            @click="select(row.id, row.purchasePreOrderGoodsId)"
          >
            选择该报价
          </label>
        </template>
      </Table>
    </div>

		 <!-- 生成订单号 -->
    <div v-if="status===5" class="title">
      <span class="icon"></span>
      <span class="text">撮合成功</span>
    </div>
    <div v-if="status===5" class="info-box-noborder">
      <dl>
        <dt>生成订单号</dt>
        <dd>{{ orderInfo.tradeId }}</dd>
      </dl>

    </div>
  </div>
</template>

<script>
import { fileUploadLimit } from '@/utils/request.js'
import { ref } from 'vue'
export default {
  name: 'DetailOrderGoods',
  props: {},
  data () {
    return {
      isEdit: false,
      columns: [],
      quotationId: '', // 选择报价商Id
      quotationOrderId: '', // 选择报价商quotationOrderId
      columnsInfo: [],
      orderInfo: {}, // 订单数据
      goodsData: [],
      infoData: [], // 报价人信息
      unitsList: [],
      editData: {}, //
      productList: [],
      status: 0,
      tradeTypeList: [
        {
          value: '全款模式',
          label: '全款模式',
        },
        {
          value: '保证金模式',
          label: '保证金模式',
        },
      ],
    }
  },
  created () {
    this.init()
  },

  methods: {
    init () {
      const params = {
        id: this.$route.params.id,
      }
      this.$store
        .dispatch('PreOrderGoodsInfo', params)
        .then((res) => {
          if (res.returnCode === '1') {

            this.goodsData = []
            this.infoData = []
            this.orderInfo = res.result
            this.orderInfo.tradeType === 1
              ? (this.orderInfo.tradeType = '全款模式')
              : (this.orderInfo.tradeType = '保证金模式')
            this.orderInfo.orderAmount =
              this.orderInfo.count * this.orderInfo.price
            this.status = res.result.status
            // this.goodsData.push(res.result)
            this.infoData = res.result.quotation
          }
        })
        .then(() => {
          this.save()
        })
      this.$store
        .dispatch('Units')
        .then((response) => {
          this.unitsList = response.result
        })
        .catch(() => {})
    },
    edit () {
      this.isEdit = true
      this.productList = []
      this.columns = [
        {
          title: '品名',
          key: 'productName',
          slot: 'productName',
          width: 160,
        },

        {
          title: '规格',
          key: 'formate',
          slot: 'formate',
          width: 100,
        },
        {
          title: '材质',
          key: 'material',
          slot: 'material',
          width: 100,
        },
        {
          title: '数量/重量',
          key: 'count',
          slot: 'count',
          width: 130,
        },
        {
          title: '单价',
          key: 'price',
          slot: 'price',
          width: 150,
        },
        {
          title: '单位',
          key: 'unitName',
          slot: 'unitName',
          width: 130,
        },
        {
          title: '交易模式',
          key: 'tradeType',
          slot: 'tradeType',
          width: 130,
        },
        {
          title: '操作',
          slot: 'action',
          width: 150,
          fixed: 'right',
          align: 'center',
        },
      ]
      const tableInfo = this.orderInfo
      this.productList.push(tableInfo)
    },
    saveEdit () {
      this.productList[0].tradeType === '全款模式'
        ? (this.productList[0].tradeType = 1)
        : (this.productList[0].tradeType = 2)
      const params = {
        id: this.productList[0].id,
        categoryId: '',
        subCategoryId: '',
        productName: this.productList[0].productName,
        formate: this.productList[0].formate,
        material: this.productList[0].material,
        count: this.productList[0].count,
        unitId: '',
        tradeType: this.productList[0].tradeType,
        unitName: this.productList[0].unitName,
        price: this.productList[0].price,
      }
      this.$store
        .dispatch('PurchaseUpdatePreOrderGoods', params)
        .then((response) => {
          if (response.returnCode === '1') {
            // that.init()
            this.$Notice.success({
              title: '商品详情修改',
              desc: '修改成功 ',
            })
          }
        })
        .then(() => {
          this.init()
        })
        .catch(() => {})
    },
    save () {
      this.isEdit = false
      this.goodsData = []
      if (this.status === 5) {
        this.columns = [
          {
            title: '品名',
            key: 'productName',
            width: 160,
          },

          {
            title: '规格',
            key: 'formate',
            width: 100,
          },
          {
            title: '材质',
            key: 'material',
            width: 100,
          },
          {
            title: '数量/重量',
            key: 'count',
            width: 100,
          },
          {
            title: '单价',
            key: 'price',
            width: 100,
          },
          {
            title: '单位',
            key: 'unitName',
            width: 100,
          },
          {
            title: '交易模式',
            key: 'tradeType',
            width: 120,
          },
          {
            title: '仓库',
            key: 'currentAddress',
            width: 200,
          },
          {
            title: '总金额',
            key: 'orderAmount',
            // fixed: 'right',
            width: 120,
          },
        ]
        this.columnsInfo = [
          {
            title: '报价单位',
            key: 'companyName',
            width: 180,
          },
          {
            title: '报价人',
            key: 'companyName',
            width: 120,
          },
          {
            title: '报价',
            key: 'price',
            width: 100,
          },
          {
            title: '联系方式',
            key: 'tel',
            width: 120,
          },
          {
            title: '邮箱',
            key: 'email',
            width: 200,
          },
          {
            title: '留言',
            key: 'remark',
            width: 220,
          },
        ]
      } else {
        this.columns = [
          {
            title: '品名',
            key: 'productName',
            width: 160,
          },

          {
            title: '规格',
            key: 'formate',
            width: 100,
          },
          {
            title: '材质',
            key: 'material',
            width: 100,
          },
          {
            title: '数量/重量',
            key: 'count',
            width: 100,
          },
          {
            title: '单价',
            key: 'price',
            width: 100,
          },
          {
            title: '单位',
            key: 'unitName',
            width: 100,
          },
          {
            title: '交易模式',
            key: 'tradeType',
            width: 120,
          },
          {
            title: '仓库',
            key: 'currentAddress',
            width: 200,
          },
          {
            title: '总金额',
            key: 'orderAmount',
            // fixed: 'right',
            width: 120,
          },
          {
            title: '操作',
            slot: 'action',
            width: 150,
            fixed: 'right',
            align: 'center',
          },
        ]
        this.columnsInfo = [
          {
            title: '报价单位',
            key: 'companyName',
            width: 180,
          },
          {
            title: '报价人',
            key: 'companyName',
            width: 120,
          },
          {
            title: '报价',
            key: 'price',
            width: 100,
          },
          {
            title: '联系方式',
            key: 'tel',
            width: 120,
          },
          {
            title: '邮箱',
            key: 'email',
            width: 200,
          },
          {
            title: '留言',
            key: 'remark',
            width: 220,
          },
          {
            title: '操作',
            slot: 'action',
            width: 150,
            fixed: 'right',
            align: 'center',
          },
        ]
      }
      this.goodsData.push(this.orderInfo)
    },
    select (quotationOrderId, id) {
      this.quotationOrderId = quotationOrderId
      this.quotationId = id
      const postData = ref({
        id: this.quotationId,
        quotationOrderId: this.quotationOrderId,
      })
      this.$store
        .dispatch('PurchaseConfirmQuotation', postData.value)
        .then((response) => {
          if (response.returnCode === '1') {
            this.$router.push('/user/purchase')
            this.$Notice.success({
              title: '确认供应商成功',
            })
          }
        })
        .catch(() => {})
    },
    contractUpload (file) {
      const fileValide = fileUploadLimit(file, this.fileLimitSize)
      if (fileValide) {
        this.$Message.error(fileValide)
        return false
      }
      return true
    },

    contractSuccess (response, file, fileList) {
      if (response.returnCode === '1') {
        this.formValidate.certificateImg.push(response.result)
        // this.$refs.formValidate.validateField('logo')
      }
    },

    contractRemove () {
      this.formValidate.logo = ''
      this.$refs.formValidate.validateField('logo')
    },
  },
}
</script>

<style scoped lang="less">
.order-info {
  padding: 0 10px;

  // h3 {
  //   font-weight: normal;
  //   font-size: 16px;
  //   padding: 25px 5px;
  // }
  .title {
    height: 20px;
    line-height: 1;
    margin-bottom: 10px;
    padding: 25px 5px;

    .icon {
      display: inline-block;
      height: 20px;
      width: 5px;
      background-color: #d12b23;
      margin-right: 10px;
      vertical-align: middle;
    }

    .text {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

// 商品表格
.goods-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 auto;

  th {
    font-weight: normal;
    line-height: 60px;
    background: #f5f5f5;

    &:first-child {
      text-align: left;
      padding-left: 20px;
    }
  }

  td {
    border-bottom: 1px solid #f5f5f5;
    text-align: center;

    &:first-child {
      padding-left: 20px;
      border-left: 1px solid #f5f5f5;
    }

    &:last-child {
      border-right: 1px solid #f5f5f5;
    }
  }

  .product {
    display: flex;
    padding: 20px 0;

    img {
      width: 70px;
      height: 70px;
      border: 1px solid #f5f5f5;
    }

    .info {
      align-self: center;
      padding-left: 20px;
      text-align: left;

      p {
        margin-bottom: 5px;
        width: 280px;
      }

      .attrs {
        color: #999;
      }
    }
  }
}

// 信息盒子
.info-box {
  border: 1px solid #dcdfe6;
  padding: 10px;
  margin-bottom: 10px;

  dl {
    display: flex;
    line-height: 30px;

    dt {
      width: 80px;
      color: #999;
      font-size: 14px;

      i {
        display: inline-block;
        width: 0.5em;
      }
    }

    dd {
      flex: 1;
    }
  }
}

.info-box-noborder {
  padding: 10px;
  margin-bottom: 10px;

  dl {
    display: flex;
    line-height: 30px;

    dt {
      width: 80px;
      color: #999;
      font-size: 14px;

      i {
        display: inline-block;
        width: 0.5em;
      }
    }

    dd {
      flex: 1;
    }
  }

  .decs-img-text {
    font-size: 12px;
    color: #909399;
  }

  .down-img-text {
    font-size: 14px;
    color: #409eff;
  }
}

// 汇总信息
.sum-info {
  // padding: 0 30px 10px 30px;
  overflow: hidden;

  .btn {
    float: right;
    width: 144px;
    background: #d12b23;
    height: 37px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border: 1px solid #cfcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info {
    width: 300px;
    // height: 160px;
    float: right;
    font-size: 14px;

    .item {
      line-height: 40px;
      display: flex;

      label {
        text-align: right;
        width: 160px;

        // font-size: 14px;
        i {
          display: inline-block;
          width: 2em;
        }
      }

      span {
        flex: 1;
        text-align: right;
        padding-right: 30px;

        &.price {
          font-size: 20px;
          color: @priceColor;
        }
      }
    }
  }
}

.steps {
  height: 73px;
  background: #f8f8fb;
  padding: 20px;
}

/deep/ .ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner {
  background: #d12b23;
  border-color: #d12b23;
}

/deep/ .ivu-steps .ivu-steps-title {
  background: #f8f8fb;
}

/deep/ .ivu-steps .ivu-steps-head {
  background: #f8f8fb;
}

/deep/ .ivu-upload {
  padding: 10px 20px 0 0;
}
</style>
